/**
 * Please keep all constants in alphabetical order, including keys in the objects
 */
import {IS_DESKTOP, IS_MAC, IS_WINDOWS, IS_CHROMEBOOK} from '@aoflmkt/is-desktop';
import {isMobile} from '@aoflmkt/is-mobile';
import {environmentTypeEnumerate} from '@aofl/server-environment';
import {getDomain, environment as serverEnvironment} from './server-environment';
import Cookies from 'js-cookie';
import {PathUtils} from '@aofl/router';

const SECURE = location.protocol === 'https:';
const DOMAIN = getDomain();

const urlSearchParams = new URLSearchParams(window.location.search);

const adjustTokens = {
  APP_TOKEN: 'bllz2vcpmgw0',
  FIRST_SIGNIN: 'tv4iw7',
  MINIREG_TOP: 'ov331n',
  MINIREG_BOTTOM: '8tvslx',
  UPGRADE_ANNUAL: 'dlmosq',
  UPGRADE_SEMI: '3un377',
  REACTIVATION_ANNUAL: 'xfxu93',
  REACTIVATION_MONTHLY: 'fd7y5w',
  SIGNIN: '10gtzm',
  SUBSCRIPTION: 'ntxvg7',
  NMHP_LOAD: 'n8t4uw',
  SUB_PAGE_LOAD: 'l5vzaf',
};

const aoflProducts = {
  ACADEMY_PRODUCT_UUID: '4d984c26-1a72-11e7-93ae-92361f002671'
};

const aoflSources = {
  ABCMOUSE_NMHP_TAB: 'aofl:abcmouse:nmhptab:1',
  AA_ANDROID_NMHP: 'mau:googleplaylink',
  ZENDESK: 'zendesk'
};

const apis = {
  MARKETING: 'academyMarketing'
};

const apiFormatters = {
  MARKETING_JSON: 'academy-json',
  LIBRARY_JSON: 'library-json'
};

const appCalls = {
  ADJUST_EVENT: 'event://adjustEvent',
  CLOSE_APP: 'application://close',
  PURCHASE: 'transaction://purchase',
  LOGIN: 'session://login',
  NAVIGATE: 'location://navigate',
  PLAY_VIDEO: 'video://video',
  RESTORE: 'transaction://restore',
  SESSION_GET: 'session://get',
  UPGRADE: 'transaction://upgrade'
};

const appCallCallbacks = {
  ADJUST_EVENT: 'adjustEventCallback',
  END_VIDEO_CALLBACK: 'endVideoCallback',
  PURCHASE: 'purchaseCallback',
  RESTORE: 'restoreCallback',
  UPGRADE: 'upgradeCallback'
};

const appCallFailureReasons = {
  DUPLICATE_TRANSACTION: 'DuplicateTransaction'
};

const appStoreActions = {
  GOOGLE_IAP: 'googleInAppPurchase',
  GOOGLE_IAU: 'googleInAppUpgrade'
};

const appStoreUserStatus = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
};

const appStores = {
  GOOGLE_PLAY: 'GooglePlay',
  AMAZON: 'Amazon',
  ITUNES: 'iTunes'
};

const cacheNamespaces = {
  API_AUTH: 'academy-auth',
  CAMPAIGN_PIXELS_LOG: 'campaign-pixels-log',
  ITUNES_RECEIPT: 'itunes-receipt',
  GOOGLE_PLAY_RECEIPT: 'google-play-receipt', // verify key name later
  LIBRARY_SESSION: 'library-session',
  USER_SESSION: 'user-session',
  PRODUCT_GROUP: 'product-group',
  ROTATION_VERSION: 'rotation-version',
  PAYPAL_USER_DATA: 'paypayl-user-data',
  RIQ_CODE_DATA: 'riq-code-data',
  REACTIVATION_USER_DATA: 'reactivation-user-data',
  TIME_LAST_VISITED: 'time-last-visited',
  REDIRECT_URL: 'redirect-url',
  LINK_TOKEN: 'link-token'
};

const cookies = {
  APP_SESSION_ID: 'academyAppSessionId',
  APP_STORE: 'academyAppStore',
  CAMPAIGN_INFO: 'campaignInfo',
  CONFIG: {
    secure: SECURE,
    domain: DOMAIN,
    expires: 365
  },
  DEVICE_ID: 'academyDeviceId',
  ELOG: 'elog_email',
  EMAIL_UNSUBSCRIBE: 'f0489350d4',
  EMPLOYEE_DISCOUNT: 'employee_discount',
  IN_APP: 'academyInApp',
  LINK_FROM_APP: 'link_from_app',
  TOKEN: 'academyToken',
  CLIENT_BACKUP_TOKEN: 'academyTokenClientBackup',
  RESET_PASSWORD: '272f199969',
  COUNTRY_CODE: 'academyCountryCode',
  REGION: 'academyRegion',
  CJ_COOKIE: 'cje',
  CJ_CONFIG: {
    secure: SECURE,
    domain: DOMAIN,
    expires: 35
  },
  WDIO: 'wdio',
  WDIO_SUPPRESS_PIXELS: 'wdio_suppress_pixels',
  WDIO_TEST_EMAIL: 'wdio_test_email',
  WDIO_SESSION_ID: 'wdio_session_id',
};

const cookiesConsent = {
  ANALYTICS: 'ANALYTICS_PERFORMANCE_COOKIES',
  TARGETING: 'TARGETING_COOKIES'
};

const countryCodesGDPR = {
  AT: 'Austria',
  AU: 'Australia',
  BE: 'Belgium',
  BG: 'Bulgaria',
  CY: 'Republic of Cyprus',
  CZ: 'Czech Republic',
  DE: 'Germany',
  DK: 'Denmark',
  EE: 'Estonia',
  ES: 'Spain',
  FI: 'Finland',
  FR: 'France',
  GB: 'United Kingdom',
  GR: 'Greece',
  HU: 'Hungary',
  HR: 'Croatia',
  IE: 'Ireland',
  IT: 'Italy',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  LV: 'Latvia',
  MT: 'Malta',
  NL: 'Netherlands',
  PL: 'Poland',
  PT: 'Portugal',
  RO: 'Romania',
  SE: 'Sweden',
  SK: 'Slovakia',
  SI: 'Slovenia'
};

const iTunesErrorsEnumerate = {
  PURCHASE_UNAVAILABLE: 0,
  EXISTING_PURCHASE_PENDING: 1,
  PRODUCT_UNAVAILABLE: 2,
  SIGNATURE_INVALID: 3,
  USER_CANCELLED: 4,
  PAYMENT_DECLINED: 5,
  DUPLICATE_TRANSACTION: 6,
  UNKNOWN: 7
};

const queryParamKeys = {
  APP_SESSION_ID: 'academyAppSessionId',
  APP_STORE: 'academyAppStore',
  DEVICE_ID: 'academyDeviceId',
  IN_APP: 'academyInApp',
  LANDING_PIXEL: 'landingPixel',
  LINK_FROM_APP: 'linkFromApp',
  PAYPAL_STATUS: 'status',
  REDEEM_CODE: 'redeemCode',
  SOURCE_TAG: 'src_tag',
  TOKEN: 'academyToken',
  PRODUCT_GROUP_HASH: '31d7a',
  ROTATION_VERSION: 'rotationVersion',
  SSO: 'sso',
  TRACK_PRODUCT: 'tap',
  TRACK_ID: 'tid',
  CJ_EVENT: 'cjevent',
  WDIO: 'wdio_rotation_version',
  WDIO_SUPPRESS_PIXELS: 'wdio_suppress_pixels',
  EXPIRATION_BYPASS_KEY: '8ru84',
  EXPIRATION_BYPASS_VALUE: '1',
  DNSMPI: 'dnsmpi',
  DIRECT_ANNUAL: 'da'
};

const queryParamValues = {
  DNSMPI: '1',
  DIRECT_ANNUAL: '1'
};

const isInApp = Cookies.get(cookies.IN_APP)?.toLowerCase() === 'true' || urlSearchParams.get(queryParamKeys.IN_APP) === 'true';
const linkFromAppParam = PathUtils.removeTrailingSlash(urlSearchParams.get('linkFromApp'));

const environment = {
  PUBLIC_PATH: __webpack_public_path__,
  IN_APP: isInApp,
  IS_ANDROID: isInApp && isMobile.android.device,
  IS_IOS: isInApp && isMobile.apple.device,
  IS_CHROMEBOOK,
  IS_DESKTOP,
  IS_LINK_FROM_APP: Cookies.get('link_from_app') === 'android' || Cookies.get('link_from_app') === 'ios' || linkFromAppParam,
  IS_MAC,
  IS_WINDOWS,
  PLATFORM: 'marketing',
  SECURE,
  DOMAIN,
  STAGE: serverEnvironment === environmentTypeEnumerate.STAGE,
  DEV: serverEnvironment === environmentTypeEnumerate.DEV,
  PROD: serverEnvironment === environmentTypeEnumerate.PROD,
};

const eventNames = {
  CAMPAIGN_CLICK: 'AOFL.ACADEMY_MARKETING.V1.marketing-campaign-click',
  IOS_PURCHASE_FAILURE: 'AOFL.ACADEMY_MARKETING.V1.ios-purchase-failure',
  GOOGLE_PURCHASE_FAILURE: 'AOFL.ACADEMY_MARKETING.V1.google-purchase-failure',
  LINK_CLICK: 'AOFL.ACADEMY_MARKETING.V1.marketing-link-click',
  PAGE_LOAD: 'AOFL.ACADEMY_MARKETING.V1.marketing-page-load',
  PIXEL_FIRE: 'AOFL.ACADEMY_MARKETING.V1.marketing-pixel-fire',
  ROTATION_ASSIGN: 'AOFL.ACADEMY_MARKETING.V1.rotation-assignment',
  VIDEO_FAILURE: 'AOFL.ACADEMY_MARKETING.V1.end-video-failure',
  VIDEO_PROGRESS: 'AOFL.ACADEMY_MARKETING.V1.video-progress',
  PAGE_LOAD_ORIENTATION: 'AOFL.ACADEMY_MARKETING.V1.marketing-page-load-orientation',
  PAGE_ORIENTATION_CHANGE: 'AOFL.ACADEMY_MARKETING.V1.marketing-page-orientation-change'
};

const libraryApis = {
  DEV_HOSTNAME: 'https://dev.adventureacademy.com',
  PROD_HOSTNAME: 'https://www.adventureacademy.com',
  STG_HOSTNAME: 'https://stage.adventureacademy.com',
  API_PATH: '/ws/amsl/0.1/json',
};

const libraryInfo = {
  PIN_TYPE_NAME: 'name',
  PIN_TYPE_NONE: 'none',
  PIN_TYPE_PIN: 'pin',
  PROTOCOL_EZPROXY: 'EZproxy',
  PROTOCOL_SIP2: 'SIP2',
};

const localStorageKeys = {
  EMAIL: 'email',
  CONVERSION_EVENT_ID: 'conversion_event_id',
  CURRENT_PRODUCT_HASH: 'currentProductHash'
};

const outboundLinks = {
  ABCMOUSE: 'https://www.abcmouse.com',
  ABCMOUSE_GAMES: 'https://www.abcmouse.com/games',
  AOFL_DEVICES: 'https://www.ageoflearning.com/devices/aofl_devices_en.pdf',
  CCPA: 'https://www.ageoflearning.com/ccpa/',
  CUSTOMER_SUPPORT: environment.PROD ? 'https://support.adventureacademy.com' : 'https://aofl1522884075.zendesk.com/hc/en-us',
  CUSTOMER_SUPPORT_CONTACT_US: 'https://support.adventureacademy.com/hc/en-us/articles/360047580314',
  PRIVACY_PORTAL: 'https://privacyportal-cdn.onetrust.com/dsarwebform/a2692794-047c-4c18-b3bf-60d783ebc844/8205df50-001c-4e16-b5f9-d4e945bc612e.html',
  IPV6: environment.PROD ? 'https://ipv6.abcmouse.com/trk' : 'https://deq4mt61w2hci.cloudfront.net/trk',
  CUSTOMER_SUPPORT_FORGOT_EMAIL: 'https://support.adventureacademy.com/hc/en-us/articles/4412611229079-Account-Access',
  CUSTOMER_SUPPORT_FORGOT_PASSWORD: 'https://support.adventureacademy.com/hc/en-us/articles/4412611229079?forgotpassword=true',
  HOMESCHOOLPLUS: 'https://www.homeschoolplus.com',
  TANDC: 'https://www.ageoflearning.com/aa-tandc-current/',
  PRIVACY_POLICY: 'https://www.ageoflearning.com/aa-privacy-current',
  KIDSAFE: 'https://www.kidsafeseal.com/certifiedproducts/adventure_academy.html'
};

const regStepsEnumerate = {
  COMPLETE: 'REGPATH_LAST_STEP',
  REACTIVATE: 'REGPATH_REACTIVATE',
  SURVEY: 'REGPATH_SURVEY',
  UPGRADE: 'REGPATH_UPGRADE',
  READINGIQ_OFFER: 'REGPATH_RIQ_OFFER',
  RIQ_OFFER_CONFIRM: 'RIQ_OFFER_CONFIRM'
};

const pages = {
  [regStepsEnumerate.REACTIVATE]: environment.PUBLIC_PATH + 'reactivation/',
  [regStepsEnumerate.UPGRADE]: environment.PUBLIC_PATH + 'upgrade/',
  [regStepsEnumerate.SURVEY]: environment.PUBLIC_PATH + 'survey/',
  [regStepsEnumerate.COMPLETE]: environment.PUBLIC_PATH + 'download/',
  ACCESSIBILITY_STATEMENT: environment.PUBLIC_PATH + 'accessibility/',
  ACCESSIBILITY_STATEMENT_LINK_FROM_ANDROID_APP: environment.PUBLIC_PATH + 'accessibility/?linkFromApp=android',
  [regStepsEnumerate.READINGIQ_OFFER]: environment.PUBLIC_PATH + 'readingiq-offer/',
  [regStepsEnumerate.RIQ_OFFER_CONFIRM]: environment.PUBLIC_PATH + 'riq-offer-confirm/',
  BONUS_OFFER: environment.PUBLIC_PATH + 'bonus-offer/',
  CDIV: environment.PUBLIC_PATH + '404/',
  CONFIRMATION: environment.PUBLIC_PATH + 'confirmation/',
  CUSTOMER_SUPPORT: environment.PUBLIC_PATH + 'customer-support/',
  CUSTOMER_SUPPORT_FORGOT_PASSWORD: environment.PUBLIC_PATH + 'support-forgot-password/',
  CUSTOMER_SUPPORT_FORGOT_EMAIL: environment.PUBLIC_PATH + 'support-forgot-email/',
  DEVICE: environment.PUBLIC_PATH + 'device/',
  DOWNLOAD_APP: environment.PUBLIC_PATH + 'download/',
  EMAIL_PREFERENCES: environment.PUBLIC_PATH + 'email-preferences/:recepient_id/',
  EXPIRED_EMLINK: environment.PUBLIC_PATH + 'expired-emlink/',
  EXTEND_ACCOUNT: environment.PUBLIC_PATH + 'extend-account/',
  FORGOT_PASSWORD: environment.PUBLIC_PATH + 'forgot-password/',
  HOME: environment.PUBLIC_PATH,
  LIBRARY_HOME_CHECK_OUT: environment.PUBLIC_PATH + 'home-check-out/',
  LIBRARY_HOME_CHECK_OUT_CREATE_ACCOUNT: environment.PUBLIC_PATH + 'home-check-out/create-account/',
  LOGIN: environment.PUBLIC_PATH + 'login/',
  MATHEMATICS: environment.PUBLIC_PATH + 'learn-math/',
  OFFER_EXPIRED: environment.PUBLIC_PATH + 'offer-expired/',
  PARENTS_SECTION: environment.PUBLIC_PATH + 'web/parent/1.0/settings/',
  PRIVACY_POLICY: outboundLinks.PRIVACY_POLICY,
  PRIVACY_POLICY_LINK_FROM_ANDROID_APP: outboundLinks.PRIVACY_POLICY + '/?linkFromApp=android',
  PRIVACY_POLICY_LINK_FROM_IOS_APP: outboundLinks.PRIVACY_POLICY + '/?linkFromApp=ios',
  PRIVACY_POLICY_PREVIOUS: outboundLinks.PRIVACY_POLICY + '/previous/',
  PRIVACY_POLICY_PREVIOUS_LINK_FROM_IOS_APP: outboundLinks.PRIVACY_POLICY + '/previous/?linkFromApp=ios',
  REACTIVATION: environment.PUBLIC_PATH + 'reactivation/',
  REDEEM: environment.PUBLIC_PATH + 'redeem/',
  REDEEM_CREATE_ACCOUNT: environment.PUBLIC_PATH + 'redeem/new/create-account/',
  REGISTER_APP: environment.PUBLIC_PATH + 'register-app/',
  REGISTER_IOS: environment.PUBLIC_PATH + 'register-ios/',
  RESET_PASSWORD: environment.PUBLIC_PATH + 'reset-password/:resetPasswordToken/',
  SUBSCRIPTION: environment.PUBLIC_PATH + 'subscription/',
  SURVEY: environment.PUBLIC_PATH + 'survey/',
  SUBSCRIPTION_ANNUAL: environment.PUBLIC_PATH + 'subscription-annual/',
  SUBSCRIPTION_OFFER_1BB8EF3: environment.PUBLIC_PATH + 'subscription/offer/1bb8ef3/',
  SUBSCRIPTION_OFFER_7CM8KPM: environment.PUBLIC_PATH + 'subscription/offer/7cm8kpm/',
  SUBSCRIPTION_OFFER_C09C263: environment.PUBLIC_PATH + 'subscription/offer/c09c263/',
  SUBSCRIPTION_OFFER_GP5R1N4: environment.PUBLIC_PATH + 'subscription/offer/gp5r1n4/',
  SUBSCRIPTION_OFFER_KNV3G45: environment.PUBLIC_PATH + 'subscription/offer/knv3g45/',
  SUBSCRIPTION_OFFER_25E5B67: environment.PUBLIC_PATH + 'subscription/offer/25e5b67/',
  SUBSCRIPTION_OFFER_4W0SQ47: environment.PUBLIC_PATH + 'subscription/offer/4w0sq47/',
  SUBSCRIPTION_OFFER_5W0SQ57: environment.PUBLIC_PATH + 'subscription/offer/5w0sq57/',
  SUBSCRIPTION_OFFER_6X1TR68: environment.PUBLIC_PATH + 'subscription/offer/6x1tr68/',
  SUBSCRIPTION_OFFER_988ABBC: environment.PUBLIC_PATH + 'subscription/offer/988abbc/',
  SUBSCRIPTION_OFFER_7294B9B: environment.PUBLIC_PATH + 'subscription/offer/7294b9b/',
  SUBSCRIPTION_OFFER_3DF3T4R: environment.PUBLIC_PATH + 'subscription/offer/3df3t4r/',
  SUBSCRIPTION_OFFER_9E4952: environment.PUBLIC_PATH + 'subscription/offer/9e4952/',
  SUBSCRIPTION_OFFER_4A1U64A: environment.PUBLIC_PATH + 'subscription/offer/4a1u64a/',
  SUBSCRIPTION_OFFER_4R5T6YA: environment.PUBLIC_PATH + 'subscription/offer/4r5t6ya/',
  SUBSCRIPTION_OFFER_GOOGLE_45_ANNUAL: environment.PUBLIC_PATH + 'subscription/offer/537e375/',
  SUBSCRIPTION_SEASONAL_OFFER_3F0DC67: environment.PUBLIC_PATH + 'subscription/seasonal/offer/3f0dc67/',
  SUBSCRIPTION_SEASONAL_OFFER_5M73XA0: environment.PUBLIC_PATH + 'subscription/offer/5m73xa0/',
  SUBSCRIPTION_SEASONAL_OFFER_6B2DB43: environment.PUBLIC_PATH + 'subscription/offer/6b2db43/',
  SUBSCRIPTION_SEASONAL_OFFER_8AB6C33: environment.PUBLIC_PATH + 'subscription/offer/8ab6c33/',
  SUBSCRIPTION_SEASONAL_OFFER_F654E6B: environment.PUBLIC_PATH + 'subscription/offer/f654e6b/',
  SUBSCRIPTION_SEASONAL_OFFER_6B1628: environment.PUBLIC_PATH + 'subscription/offer/6b1628/',
  SUBSCRIPTION_SEASONAL_OFFER_5012C22: environment.PUBLIC_PATH + 'subscription/offer/5012c22/',
  SUBSCRIPTION_SEASONAL_OFFER_C908DC6: environment.PUBLIC_PATH + 'subscription/offer/c908dc6/',
  TANDC: outboundLinks.TANDC,
  TANDC_LINK_FROM_ANDROID_APP: outboundLinks.TANDC + '/?linkFromApp=android',
  TANDC_LINK_FROM_IOS_APP: outboundLinks.TANDC + '/?linkFromApp=ios',
  UPGRADE: environment.PUBLIC_PATH + 'upgrade/',
  UPGRADE_STANDALONE: environment.PUBLIC_PATH + 'upgrade/sta/',
  WORDPLAY: environment.PUBLIC_PATH + 'wordplay/',
  EMPLOYEE: environment.PUBLIC_PATH + '1e56d9f/',
  READINGIQ_OFFER: environment.PUBLIC_PATH + 'readingiq-offer/',
  RIQ_OFFER_CONFIRM: environment.PUBLIC_PATH + 'riq-offer-confirm/'
};

const paymentTypes = {
  CREDIT_CARD: 'PAYMENTECH',
  ITUNES: 'ITUNES',
  AMAZON: 'AMAZON',
  PAYPAL: 'PAYPAL',
  GOOGLE: 'GOOGLE_IAP'
};

const payPalOriginPages = {
  SUBSCRIPTION: 'SUBSCRIPTION',
  SUBSCRIPTION_ANNUAL: 'SUBSCRIPTION_ANNUAL',
  SUBSCRIPTION_OFFER_1BB8EF3: 'SUBSCRIPTION_OFFER_1BB8EF3',
  SUBSCRIPTION_OFFER_7CM8KPM: 'SUBSCRIPTION_OFFER_7CM8KPM',
  SUBSCRIPTION_OFFER_C09C263: 'SUBSCRIPTION_OFFER_C09C263',
  SUBSCRIPTION_OFFER_GP5R1N4: 'SUBSCRIPTION_OFFER_GP5R1N4',
  SUBSCRIPTION_OFFER_KNV3G45: 'SUBSCRIPTION_OFFER_KNV3G45',
  SUBSCRIPTION_OFFER_25E5B67: 'SUBSCRIPTION_OFFER_25E5B67',
  SUBSCRIPTION_OFFER_4W0SQ47: 'SUBSCRIPTION_OFFER_4W0SQ47',
  SUBSCRIPTION_OFFER_5W0SQ57: 'SUBSCRIPTION_OFFER_5W0SQ57',
  SUBSCRIPTION_OFFER_6X1TR68: 'SUBSCRIPTION_OFFER_6X1TR68',
  SUBSCRIPTION_OFFER_7294B9B: 'SUBSCRIPTION_OFFER_7294B9B',
  SUBSCRIPTION_OFFER_988ABBC: 'SUBSCRIPTION_OFFER_988ABBC',
  SUBSCRIPTION_OFFER_3DF3T4R: 'SUBSCRIPTION_OFFER_3DF3T4R',
  SUBSCRIPTION_OFFER_537e375: 'SUBSCRIPTION_OFFER_537e375',
  SUBSCRIPTION_OFFER_00f7d4d: 'SUBSCRIPTION_OFFER_00f7d4d',
  SUBSCRIPTION_OFFER_9E4952: 'SUBSCRIPTION_OFFER_9E4952',
  SUBSCRIPTION_OFFER_4R5T6YA: 'SUBSCRIPTION_OFFER_4R5T6YA',
  SUBSCRIPTION_OFFER_4A1U64A: 'SUBSCRIPTION_OFFER_4A1U64A',
  SUBSCRIPTION_SEASONAL_OFFER_3F0DC67: 'SUBSCRIPTION_SEASONAL_OFFER_3F0DC67',
  SUBSCRIPTION_SEASONAL_OFFER_5M73XA0: 'SUBSCRIPTION_SEASONAL_OFFER_5M73XA0',
  SUBSCRIPTION_SEASONAL_OFFER_6B2DB43: 'SUBSCRIPTION_SEASONAL_OFFER_6B2DB43',
  SUBSCRIPTION_SEASONAL_OFFER_8AB6C33: 'SUBSCRIPTION_SEASONAL_OFFER_8AB6C33',
  SUBSCRIPTION_SEASONAL_OFFER_F654E6B: 'SUBSCRIPTION_SEASONAL_OFFER_F654E6B',
  SUBSCRIPTION_SEASONAL_OFFER_6B1628: 'SUBSCRIPTION_SEASONAL_OFFER_6B1628',
  SUBSCRIPTION_SEASONAL_OFFER_5012C22: 'SUBSCRIPTION_SEASONAL_OFFER_5012C22',
  SUBSCRIPTION_SEASONAL_OFFER_C908DC6: 'SUBSCRIPTION_SEASONAL_OFFER_C908DC6',
  REACTIVATION: 'REACTIVATION'
};

const pixelEvents = {
  CONVERSION: 'conversion',
  LANDING: 'landing',
  PROSPECT_REGISTRATION: 'prospectRegistration'
};

const privacyPolicySections = {
  CALIFORNIA_PRIVACY_RIGHTS: pages.PRIVACY_POLICY + '?scrollTo=california-privacy-rights/',
  CALIFORNIA_PRIVACY_RIGHTS_LINK_FROM_ANDROID_APP: pages.PRIVACY_POLICY + '?linkFromApp=android&scrollTo=california-privacy-rights/',
  CALIFORNIA_PRIVACY_RIGHTS_LINK_FROM_IOS_APP: pages.PRIVACY_POLICY + '?linkFromApp=ios&scrollTo=california-privacy-rights/'

};

const productGroups = {
  ANNUAL_5999: '894ef3c9-bc20-4254-8bc2-3b3ff9a4dde6',
  ANNUAL_GOOGLE_IAP_5999: 'f4ccff75-3fc9-49a2-842a-bc020219b2f4',
  ANNUAL_GOOGLE_45: 'b7227f79-5090-450d-a154-cbcaf9b70cf8',
  ANNUAL_UPGRADE_GOOGLE_IAP_5999: '112f48cf-0ece-4b46-aacd-443fc75ce639',
  ANNUAL_IOS_NO_TRIAL_5999: '492eae95-3c1e-4afe-8eb7-03ac74a9feae',
  ANNUAL_7999: 'f9688025-65d9-49e0-8dfb-3621566d803e',
  ANNUAL_GOOGLE_IAP_7999: 'bbd9a884-f338-4b6a-8914-4d6765f89226',
  ANNUAL_NO_TRIAL_GOOGLE_IAP_7999: 'ca1ea610-0e45-471e-abe8-f7191f5b0f5e',
  ANNUAL_HOLIDAY_OFFER_4500: '5596c249-b023-4580-8541-f9074d6c7179',
  ANNUAL_HOLIDAY_OFFER_CIT_MIT_4500: '4090d32a-225e-43f8-aa52-d633640f91d0',
  ANNUAL_TRIAL_7999: '3ae7ad17-eb0b-4b03-b572-3ee06adade47',
  ANNUAL_SEMI_2999: '45b1adb7-dfbf-4e71-97f4-759a9357dded',
  ANNUAL_SEMI_CIT_MIT_2999: '3a343e8f-3175-4033-95c7-8c4231651e1c',
  ANNUAL_SEMI_UPGRADE_GOOGLE_IAP_2999: 'da487579-343f-48f3-99c7-d1fe3dab32bd',
  ANNUAL_EXTEND_4500: 'cbad366a-b4c7-11ea-b3de-0242ac130004',
  ANNUAL_NO_TRIAL_2999: 'b88fb21e-46de-4dea-ac31-09a82de589fa',
  ANNUAL_4500_PLUS_RIQ_BUNDLE: '6833f146-9d36-4104-bde8-b0d954e27ed1',
  MONTHLY_TRIAL_RIQ: 'de13601c-d3c8-46c1-bab3-452a78c43442',
  MONTHLY_TRIAL_999: '169097e9-fc0b-4e50-b816-d0404eb94bc9',
  MONTHLY_TRIAL_GOOGLE_IAP_999: '2989bf8f-fb7b-4d52-ade5-4dfcdb67bb6a',
  MONTHLY_TRIAL_GOOGLE_IAP_1299: 'ab4aeb2c-6d05-4bd4-b9f3-2b753d421b9d',
  MONTHLY_IOS_TRIAL_1299: 'af25a92b-1458-4626-aecb-3d96f62cf349',
  MONTHLY_IOS_1299: '1446c262-4d26-4b7c-bc01-a99588d61b28',
  MONTHLY_TRIAL_1299: '745cb3fc-99b0-48ee-ba52-4c76972b2937',
  MONTHLY_NO_TRIAL_999: 'dd608b48-468b-4120-9e27-a4d65a3c74e5',
  MONTHLY_NO_TRIAL_1299: '9d3685e3-27b0-444b-afef-09077254ab81',
  MONTHLY_FIRST_MONTH_DOLLAR: 'c7090b6f-7e40-4ee1-83a5-f0e77105dd68',
  MONTHLY_FIRST_2_MONTHS_5_DOLLAR: 'd2aa708e-4a41-4813-86d0-7cdc3afe9ed1',
  MONTHLY_NO_TRIAL_GOOGLE_IAP_999: '6a6d4232-4764-42f0-98a5-c3c1ab2fa880',
  MONTHLY_NO_TRIAL_GOOGLE_IAP_1299: 'fc454e1b-0517-459b-a669-8fc775669d4f',
  GROUPON_ANNUAL_SEMIANNUAL_1299: '5610a279-a9ad-427c-b682-f92b96bc93eb',
  MONTHLY_SEASONAL_OFFER_999: '042bf672-879b-48b5-9021-d11c7ec3192d',
  FMF_3DAY_TRIAL: {
    MONTHLY_999: '961a9e0e-c62c-4180-93c0-bb409050f557',
    ANNUAL_7999: 'f892de88-b07e-41a2-bcf5-99b88d103d9f'
  },
  FMF_7DAY_TRIAL: {
    MONTHLY_999: '21bcce50-d202-47fa-9698-e8b7db7b4a9a',
    MONTHLY_1299: '2110c475-7d60-4e46-ade9-5b36b95f05f6',
    ANNUAL_7999: '7895fd55-334b-4e3c-896b-1a73d7087ae1'
  },
  MONTHLY_2_MONTH_TRIAL_995: 'be5eb6a1-84fc-4755-aeb0-c2e173e4fa7f',
  MONTHLY_TRIAL_999_3_MONTHS: '2b0dcb3b-4420-4b61-86cd-ddde3be59087',
  MONTHLY_$10_FOR_3_MONTHS_THEN_1299: 'a668773e-e7cf-4d69-9e0e-6cfeca612987',
  MONTHLY_TRIAL_1_1_MONTH: '25e31f9b-13f6-4f30-a18d-f80dcdcb3c9c',
  MONTHLY_2MF_1299: 'e52d7369-e023-4e21-a065-1a52dc3e1122',
  MONTHLY_999_FOR_3_MONTHS: 'ae17b590-34e0-4b9c-90eb-62d5eface765',
  MONTHLY_CORPORATE_FMF_899: '0c345e28-2b47-41ed-ba10-47ed4eb9f290',
  MONTHLY_IOS_TRIAL_1999: 'fcbddd98-999e-49d6-9abe-b673c0a95a96',
  ANNUAL_IOS_NO_TRIAL_9999: 'a0634c45-caf4-43c9-ac48-19f14b2ebb31',
  MONTHLY_IOS_TRIAL_2499: 'd2622f7f-f91d-475b-91d7-e933902515c6',
  ANNUAL_IOS_NO_TRIAL_9999_2499: 'f5ac20ed-aba1-44ab-9f1b-a711bc8eb457',
  MONTHLY_1299_FMF_45: '1c3e5dad-eb71-41f1-b385-b6dda3dd9ce9',
  MONTHLY_1299_FMF_CIT_MIT_45: '76f98262-85a3-49f8-a8e3-b409420833f9',
  MONTHLY_1299_NO_FMF_45: '7286f13b-8c1d-4a26-a938-e29714cfcf6e',
  READINGIQ_UPSELL_OFFER: '4cecdade-9dd0-4443-ae5f-28f06be642e4',
  READINGIQ_UPSELL_OFFER_ANNUAL_12_00: '5fddb64e-e090-42c6-85d5-674aafe64337',
  READINGIQ_UPSELL_OFFER_ANNUAL_CIT_MIT_12_00: '3e870d72-e0eb-4b90-96e9-1e1ec148763c',
  MONTHLY_6_for_6_THEN_1299: '481b8a40-660c-4487-93bc-ffea1a553b74',
  MONTHLY_4_FOR_20: 'bc2ca286-2add-41b6-9510-18636115e74d'
};

const redeemCodeTypes = {
  GILTCITY: 'giltcity',
  GROUPON: 'groupon',
  RUELALA: 'ruelala'
};

const rotations = {
  MARKETING: 'academyMarketing'
};

const rotationVersion = {
  ROTATION_VERSION_1094: '1094'
};

const sdoNamespaces = {
  ACADEMY_PAYMENT_FORM: 'paymentForm',
  ACADEMY_REGISTER_FORM: 'registerForm',
  HOME_CAROUSEL: 'homeCarousel',
  HOME_CAROUSEL_MODAL: 'homeCarouselModal',
  HOME_EMPLOYEE_CAROUSEL: 'homeEmployeeCarousel',
  HOME_EMPLOYEE_CAROUSEL_MODAL: 'homeEmployeeeCarouselModal',
  REDEEM_HOME_CAROUSEL: 'redeemHomeCarousel',
  REDEEM_HOME_CAROUSEL_MODAL: 'redeemHomeCarouselModal',
  SUBSCRIPTION_OFFER_KNV3G45: 'subscriptionOfferknv3g45',
  SUBSCRIPTION_OFFER_KNV3G45_EMAIL_IN_USE_MODAL: 'subscriptionOfferknv3g45EmailInUseModal',
  SUBSCRIPTION_OFFER_KNV3G45_WELCOME_BACK_MODAL: 'subscriptionOfferknv3g45WelcomeBackModal',
  USER_INFO: 'userInfo',
};

const subscriberTypes = {
  SUBSCRIPTION: 'subscription',
  REACTIVATION: 'reactivation'
};

const surveyLocations = {
  REG_PATH: 'reg_path',
  CANCELLATION_PATH: 'cancellation_path'
};

export {
  adjustTokens,
  aoflProducts,
  aoflSources,
  apis,
  apiFormatters,
  appCalls,
  appCallCallbacks,
  appCallFailureReasons,
  appStoreActions,
  appStoreUserStatus,
  appStores,
  cacheNamespaces,
  cookies,
  cookiesConsent,
  countryCodesGDPR,
  environment,
  eventNames,
  iTunesErrorsEnumerate,
  libraryApis,
  libraryInfo,
  localStorageKeys,
  outboundLinks,
  pages,
  paymentTypes,
  payPalOriginPages,
  pixelEvents,
  privacyPolicySections,
  productGroups,
  queryParamKeys,
  queryParamValues,
  redeemCodeTypes,
  regStepsEnumerate,
  rotations,
  rotationVersion,
  sdoNamespaces,
  subscriberTypes,
  surveyLocations
};
