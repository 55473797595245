import baseline from './baseline';
import is7008TwoStepV2 from './7008-two-step-v2';
import is6568iOSIAPTest from './6568-ios-iap-test';
import is7005GooglePlayTest from './7005-google-play-test';

export default {
  baseline,
  '7008-two-step-v2': is7008TwoStepV2,
  '6568-ios-iap-test': is6568iOSIAPTest,
  '7005-google-play-test': is7005GooglePlayTest,
};
